// src/components/CreateLicense.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, MenuItem } from '@mui/material';

const CreateLicense = () => {
    const [licenseKey, setLicenseKey] = useState(crypto.randomUUID());
    const [status, setStatus] = useState('active');
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const currentTimestamp = new Date();
        const oneMonthLater = new Date();
        oneMonthLater.setMonth(currentTimestamp.getMonth() + 1);

        // Adjusting keys to match SQL schema
        const payload = {
            license_key: licenseKey,
            status: status,
            user_email: userEmail || null,
            activated_at: currentTimestamp.toISOString(),
            expires_at: oneMonthLater.toISOString(),
        };

        try {
            await axios.post(`https://wpab-license-server.azurewebsites.net/api/Create-License`, payload, {
                params: {
                    code: "I0pIUjkzv05x0iDOUxsUSrR6fDGIY7Yfpejn7mdBvYwNAzFuPYHeLA=="
                }
            });
            alert('License created successfully.');
            navigate('/');
        } catch (error) {
            console.error('Error creating license:', error);
            alert(`Failed to create license. ${error.response ? error.response.data : ''}`);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Create New License</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="License Key"
                    value={licenseKey}
                    onChange={(e) => setLicenseKey(e.target.value)}
                    required
                    fullWidth
                    margin="normal"
                />
                <TextField
                    select
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                    fullWidth
                    margin="normal"
                >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                    <MenuItem value="revoked">Revoked</MenuItem>
                </TextField>
                <TextField
                    label="User Email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                    Create License
                </Button>
            </form>
        </Container>
    );
};

export default CreateLicense;
