// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LicenseList from './components/LicenseList';
import CreateLicense from './components/CreateLicense';
import EditLicense from './components/EditLicense';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';

function App() {
    return (
        <Router>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">WPAB License Manager</Typography>
                </Toolbar>
            </AppBar>
            <Container style={{ marginTop: '2rem' }}>
                <Routes>
                    <Route path="/" element={<LicenseList />} />
                    <Route path="/create" element={<CreateLicense />} />
                    <Route path="/edit/:license_key" element={<EditLicense />} />
                </Routes>
            </Container>
        </Router>
    );
}

export default App;
