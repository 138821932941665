// src/components/LicenseList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, Container } from '@mui/material';

const LicenseList = () => {
    const [licenses, setLicenses] = useState([]);
    const [loading, setLoading] = useState(true);

    // Define API URL and key directly in the component
    const API_BASE_URL = "https://wpab-license-server.azurewebsites.net/api";
    const READ_LICENSES_KEY = "mYiCXY_nPS9-rKiX2ANZzjeFcBQ1EB0NScXRTPq6eDbmAzFuvWr9sA==";
    const REVOKE_LICENSE_KEY = "tZvg0kSVC4ZOMYGA4kVr4DKGg9JeC8fXGtgfg5eqlPmQAzFuCVZk5g==";

    useEffect(() => {
        fetchLicenses();
        // eslint-disable-next-line
    }, []);

    const fetchLicenses = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/Read-Licenses`, {
                params: {
                    code: READ_LICENSES_KEY
                }
            });
            setLicenses(response.data);
        } catch (error) {
            console.error('Error fetching licenses:', error);
            alert('Failed to fetch licenses.');
        } finally {
            setLoading(false);
        }
    };

    const revokeLicense = async (license_key) => {
        if (!window.confirm('Are you sure you want to revoke this license?')) return;

        try {
            await axios.post(`${API_BASE_URL}/Revoke-License`, null, {
                params: {
                    license_key,
                    code: REVOKE_LICENSE_KEY
                }
            });
            alert('License revoked successfully.');
            fetchLicenses();
        } catch (error) {
            console.error('Error revoking license:', error);
            alert('Failed to revoke license.');
        }
    };

    if (loading) {
        return (
            <Container>
                <Typography variant="h5">Loading licenses...</Typography>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>License List</Typography>
            <Link to="/create" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary" style={{ marginBottom: '20px' }}>Create New License</Button>
            </Link>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>License Key</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>User Email</TableCell>
                        <TableCell>Activated At</TableCell>
                        <TableCell>Expires At</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {licenses.map((license) => (
                        <TableRow key={license.id}>
                            <TableCell>{license.licenseKey}</TableCell>
                            <TableCell>{license.status}</TableCell>
                            <TableCell>{license.userEmail || 'N/A'}</TableCell>
                            <TableCell>{license.activatedAt ? new Date(license.activatedAt).toLocaleString() : 'N/A'}</TableCell>
                            <TableCell>{license.expiresAt ? new Date(license.expiresAt).toLocaleString() : 'N/A'}</TableCell>
                            <TableCell>{license.url || 'N/A'}</TableCell>
                            <TableCell>
                                <Link to={`/edit/${license.licenseKey}`} style={{ textDecoration: 'none' }}>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '8px' }}>Edit</Button>
                                </Link>
                                <Button variant="outlined" color="secondary" onClick={() => revokeLicense(license.licenseKey)}>Revoke</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </Container>
    );
};

export default LicenseList;
